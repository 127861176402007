import axios from 'axios';

const fetchUserTokenTransferEvents = async (contractAddress, userAddress, ascending=true) => {
  const url = `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=${contractAddress}&address=${userAddress}&page=1&offset=10000&sort=${ascending?"asc":"desc"}`; // &apikey=YourApiKeyToken

  const res = await axios.get(url);
  if (res.status === 200) {
    // const userTokenTransferEvents = res.data.result;
    return res.data.result;
  } else {
    console.warning('Warning: failed to fetch userTokenTransferEvents from etherscan.');
    return [];
  }
};

export default fetchUserTokenTransferEvents;

// url example: https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=0x67ab11058ef23d0a19178f61a050d3c38f81ae21&address=0x7549fa152b92BBC818285e6D0A48857381fAAB5D&page=1&offset=10000&sort=desc

/*
res:
{
  status: 200,
  data: {
    "status": "1",
    "message": "OK",
    "result": [
      {
        "blockNumber": "8744220",
        "timeStamp": "1571119974",
        "hash": "0x08d342f4de9ffe7756d661fe5f4939d75ddc4ebecc7d437238a5a577b2d83865",
        "nonce": "763",
        "blockHash": "0x66a08daefe057eda11e6bc95f906a878fa3b700feb760f6485c69f0626508f73",
        "from": "0x10a51d96deaac5f3266b965901e5c83dc27ac00b",
        "contractAddress": "0x67ab11058ef23d0a19178f61a050d3c38f81ae21",
        "to": "0x9dbd361c6c8af19eb4ea20605dc49fd4731f2248",
        "value": "123000000000000000000",
        "tokenName": "SELF TOKEN",
        "tokenSymbol": "SELF",
        "tokenDecimal": "18",
        "transactionIndex": "87",
        "gas": "150000",
        "gasPrice": "1000000001",
        "gasUsed": "91680",
        "cumulativeGasUsed": "6269604",
        "input": "deprecated",
        "confirmations": "415935"
      },
      ...
    ]
  }
}
*/