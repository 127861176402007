import React from "react";
import * as moment from 'moment';
import BigNumber from 'utils/BigNumber';


export function formatTransaction(txHash, withIcon=true) {
  if (typeof(txHash) !== "string") { return ''; }
  if (withIcon) {
    return (
      <span>
        <span className="font-monospace" title={ txHash }>{ `${txHash.substr(2, 8)}` } </span>
        <a className="font-monospace" title={ txHash } href={ `https://etherscan.io/tx/${txHash}` } target="_blank" rel="noopener noreferrer">
          <i className="fas fa-external-link-alt"></i>
        </a>
      </span>
    );
  }
  return (
    <a className="font-monospace" title={ txHash } href={ `https://etherscan.io/tx/${txHash}` } target="_blank" rel="noopener noreferrer">
      { `${txHash.substr(2, 8)} ` }
    </a>
  );
}

export function formatTransactionIcon(txHash) {
  if (typeof(txHash) !== "string") { return ''; }
  return (
    <a className="font-monospace" title={ txHash } href={ `https://etherscan.io/tx/${txHash}` } target="_blank" rel="noopener noreferrer">
      <i className="fas fa-external-link-alt"></i>
    </a>
  );
}

export function formatAddress(address) {
  if (typeof(address) !== "string") { return ''; }
  return (
    <a className="font-monospace" title={ address } href={ `https://etherscan.io/token/0x67ab11058ef23d0a19178f61a050d3c38f81ae21?a=${address}` } target="_blank" rel="noopener noreferrer">
      { `${address.substr(2, 8)}...${address.substr(-4)}` }
    </a>
  );
}

export function formatTimestamp(timestamp) {
  let timestampNum;
  if (typeof(timestamp) === "number") {
    timestampNum = timestamp;
  }
  else if (typeof(timestamp) === "string") {
    timestampNum = parseInt(timestamp);
  }
  else { // BigNumber
    timestampNum = timestamp.toNumber();
  }
  return moment.unix(timestampNum).format("YYYY-MM-DD HH:mm");
}

export function formatWeiToEther(wei, decimalPlaces=2) {
  const weiBN = new BigNumber(wei);
  const etherBN = weiBN.div(1e18);
  const roundedEtherBN = new BigNumber(etherBN.toFixed(decimalPlaces));
  return roundedEtherBN.toFormat();
}
