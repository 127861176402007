import axios from 'axios';


const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
};

const fetchAddressInfo = async () => {
  // https://raw.githubusercontent.com/self-token/global-data/master/addressInfo.json
  const url = 'https://self-token.github.io/global-data/addressInfo.json';

  const res = await axios.get(url);
  if (res.status === 200) {
    return res.data;
  } else {
    console.error('Error: failed to fetchAddressInfo.');
    await sleep(1000);
    const addressInfo = await fetchAddressInfo();
    return addressInfo;
  }
};

export default fetchAddressInfo;
