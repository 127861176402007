import React, { Component } from "react";
// import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';
import Web3 from "web3";
import BigNumber from 'utils/BigNumber';
// import BN from 'bn.js'; // dependency of web3
// import Iframe from 'react-iframe';
// import { Navbar, Nav } from 'react-bootstrap';

import "styles/AddressInput.css";
import "styles/NumericInput.css";
// import "App.css";

import SelfTokenJSON from "contracts/SelfToken.json";
import localesData from 'data/locales.json';

import * as addressUtils from "utils/addressUtils"; // do `await addressUtils.init()` before using

import { Spinner } from "components/Spinner.js";
import LanguageSelector from "components/LanguageSelector.js";
import DoubleBorderTitle from "components/DoubleBorderTitle.js";
import AddressName from 'components/AddressName.js';
// import MerchantBalances from "components/merchant/MerchantBalances.js";
import MerchantReceiveToken from "components/merchant/MerchantReceiveToken.js";
import MerchantOrders from "components/merchant/MerchantOrders.js";
import { formatWeiToEther } from 'utils/formatData';


const strings = new LocalizedStrings(localesData);
const pages = Object.freeze({
  receive_payment: 1,
  dashboard: 2,
});

class MerchantApp extends Component {
  state = {
    web3: null,
    // networkId: 1,
    contractAddress: '',
    selfTokenContract: null,
    address: '',
    isMerchantAddress: false,
    isShowingBalances: false,
    accountState: {
      // ethBalanceBN: new BigNumber(0),
      selfBalanceBN: new BigNumber(0),
    },
    isAllDataFetched: false,
    pollIntervalId: -1,
    showingPage: pages.receive_payment,
  };

  componentDidMount = async () => {
    try {
      console.log("MerchantApp this.props", this.props);

      const address = this.props.match.params.address;
      await addressUtils.init();
      const isMerchantAddress = addressUtils.getIsMerchantAddress(address);
      const addressInfo = addressUtils.getAddressInfo(address);
      console.log("address", address);
      this.setState({
        address,
        isMerchantAddress,
        addressInfo,
      });

      if (isMerchantAddress) {
        const provider = new Web3.providers.HttpProvider(
          "https://mainnet.infura.io/v3/9e7e582e49d14542b1dc8086c96d398e" // roger@selftoken.co - self pay merchant
        );
        const web3 = new Web3(provider);

        // Init contract instance.
        const networkId = 1;
        const contractAddress = SelfTokenJSON.networks[networkId].address;
        const selfTokenContract = new web3.eth.Contract(
          SelfTokenJSON.abi,
          contractAddress
        );
        await this.setState({
          web3,
          contractAddress,
          selfTokenContract,
        });

        const pollIntervalId = setInterval(() => {
          this.poll();
        }, 10000);
        this.setState({ pollIntervalId });
        await this.poll();
      }

      this.setState({
        isAllDataFetched: true,
      });
    } catch (error) {
      console.error('MerchantApp componentDidMount', error);
      this.setState({
        isAllDataFetched: true,
      });
    }
  }

  componentWillUnmount = () => {
    const { pollIntervalId } = this.state;
    if (pollIntervalId !== -1) {
      clearInterval(pollIntervalId);
    }
  }

  poll = async () => {
    console.log("poll");

    this.fetchAccountState();
  }

  fetchAccountState = async () => {
    console.log("fetchAccountState");

    const { address, selfTokenContract } = this.state;

    // If there are no accounts injected, return.
    if (!address) {
      return;
    }

    // // promises
    // const getEthBalance = web3.eth.getBalance(address);
    // const getSelfBalance = selfTokenContract.methods.balanceOf(address).call();
    // const values = await Promise.all([getEthBalance, getSelfBalance]);
    // const ethBalanceStr = values[0];
    // const selfBalanceStr = values[1];

    // // Make a clone of accountState.
    // let accountState = Object.assign({}, this.state.accountState);
    // accountState.ethBalanceBN = new BN(ethBalanceStr);
    // accountState.selfBalanceBN = new BN(selfBalanceStr);

    const selfBalanceStr = await selfTokenContract.methods.balanceOf(address).call();
    const selfBalanceBN = new BigNumber(selfBalanceStr);
    const accountState = {
      selfBalanceBN,
    }

    // Update state with the result.
    this.setState({ accountState });
  };

  setLangCode = (langCode) => {
    strings.setLanguage(langCode);
    this.setState({});
  };

  toggleBalances = () => {
    this.setState({
      isShowingBalances: !this.state.isShowingBalances,
    });
  };

  setPage = (page) => {
    this.setState({
      showingPage: page,
    });
  };

  render() {
    const {
      location,
    } = this.props;

    const {
      contractAddress,
      address,
      isMerchantAddress,
      addressInfo,
      accountState,
      isAllDataFetched,
      // isShowingBalances,
      showingPage,
    } = this.state;
    const { selfBalanceBN } = accountState;

    let content = null;
    if (!isAllDataFetched) {
      content = <Spinner/>;
    } else if (!isMerchantAddress) {
      content = <div className="container">
        <div className="alert alert-danger">
        { strings.Invalid_Merchant_Address }
        </div>
      </div>;
    } else {
      if (showingPage === pages.receive_payment) {
        const nowTimeStamp = Math.floor(Date.now() / 1000);
        const fromTimeStamp = Math.floor(nowTimeStamp / 86400) * 86400;
        console.log('fromTimeStamp', fromTimeStamp);
        content = (
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="mb-4">
                {/* <DoubleBorderTitle paddingType="narrow">{ strings.Store }</DoubleBorderTitle> */}
                <div className="text-center">
                  <div onClick={ this.toggleBalances }>
                    <AddressName address={ address } />
                  </div>
                  {/* {
                    isShowingBalances && <>
                      <div className="text-additional">{ address }</div>
                      <div className="text-additional">{ formatWeiToEther(selfBalanceBN) } SELF</div>
                    </>
                  } */}
                </div>
              </div>

              <div className="mb-3">
                <DoubleBorderTitle paddingType="narrow">{ strings.Receive_SELF }</DoubleBorderTitle>
                <MerchantReceiveToken
                  address={ address }
                  strings={ strings }
                  location={ location }
                  addressInfo={ addressInfo }
                />
              </div>
            </div>

            <div className="col-md-7">
              <DoubleBorderTitle paddingType="narrow"><span title="當日交易為每日 8:00 到隔日 8:00 之間的交易">{ strings.Today_s_Transactions }</span></DoubleBorderTitle>
              <MerchantOrders
                merchantAddress={ address }
                strings={ strings }
                fromTimeStamp={ fromTimeStamp }
                isGroupedByMonth={ false }
              />
              <div className="text-additional">如果一筆交易應該已經完成卻遲遲未出現，請至 <a href={ `https://etherscan.io/token/${contractAddress}?a=${address}` } target="_blank" rel="noopener noreferrer">Etherscan</a> 查看交易是否已完成。
              </div>
            </div>
          </div>
        </div>);
      }
      else if (showingPage === pages.dashboard) {
        content = (
        <div className="container">
          <div className="text-center mb-5">
            <AddressName address={ address } />
            <div className="">{ address }</div>
            <div className="">{ formatWeiToEther(selfBalanceBN) } SELF</div>
          </div>

          <DoubleBorderTitle paddingType="narrow">{ strings.All_Transactions }</DoubleBorderTitle>
          <MerchantOrders
            merchantAddress={ address }
            strings={ strings }
            fromTimeStamp={ 0 }
            isGroupedByMonth={ true }
          />
        </div>);
      }
    }

    const navbar = <div className="custom-navbar mb-3 clearfix">
      <div className="float-left">
        <div>
          <span className="app-title">SELF DAPP - Merchant</span>
          <span className="a custom-nav-link" onClick={ () => { this.setPage(pages.receive_payment) } }>{ strings.Receive_Payment }</span>
          <span className="a custom-nav-link" onClick={ () => { this.setPage(pages.dashboard) } }>{ strings.Dashboard }</span>
        </div>
      </div>
      <div className="float-right">
        <div className="d-inline-block">
          <a className="mr-3" href="https://docs.google.com/document/d/1Tm8n4NjQ9QQQhPmVmlOZzNaa-pUySzBQO-nxbKG4sP8/edit?usp=sharing" target="_blank" rel="noopener noreferrer">{ strings.How_to_Use }</a>
        </div>
        <div className="d-inline-block">
          <LanguageSelector strings={strings} setLangCode={this.setLangCode} />
        </div>
      </div>
    </div>;

    const app = <div className="App">
      <div className="bg">
        <div className="line"></div>
      </div>
      { navbar }
      { content }
    </div>;

    return app;
  }
}

export default MerchantApp;
