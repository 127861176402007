import fetchAddressInfo from 'utils/fetchAddressInfo';


let isInitialized = false;
let addressInfo = [];
let addressToInfo = {};

// addresses must be in lower case
const addressesThatCanNotOwnScore = new Set([
  '0x0000000000000000000000000000000000000000',
]);

export const init = async () => {
  if (isInitialized) { return; }
  addressInfo = await fetchAddressInfo();
  for (const info of addressInfo) {
    addressToInfo[info.address.toLowerCase()] = info;
  }
  isInitialized = true;
};

export const canAddressOwnScoreAsync = async (address) => {
  if (!isInitialized) { await init(); }
  const lowercase_address = address.toLowerCase();
  if (addressesThatCanNotOwnScore.has(lowercase_address)) {
    return false;
  }
  return true;
};

export const getAddressScoreMultiplierAsync = async (address) => {
  if (!isInitialized) { await init(); }
  const lowercase_address = address.toLowerCase();
  if (addressToInfo.hasOwnProperty(lowercase_address)) {
    return addressToInfo[lowercase_address].selfer_score_multiplier;
  }
  return 0;
};

export const getAddressNameAsync = async (address) => {
  if (!isInitialized) { await init(); }
  const lowercase_address = address.toLowerCase();
  if (addressToInfo.hasOwnProperty(lowercase_address)) {
    return addressToInfo[lowercase_address].full_name;
  }
  return '';
};

export const getAddressName = (address) => {
  const lowercase_address = address.toLowerCase();
  if (addressToInfo.hasOwnProperty(lowercase_address)) {
    return addressToInfo[lowercase_address].full_name;
  }
  return '';
};

// {
//   "address": "0xd79ed209ce09d0d92c7fbde8152be5ca672a64fa",
//   "full_name": "SELF 私室",
//   "name_beside_logo": "",
//   "logo_file_name": "self_bar_v2.png",
//   "is_feeless_in": 1,
//   "selfer_score_multiplier": 1
// },
export const getAddressInfo = (address) => {
  const lowercase_address = address.toLowerCase();
  if (addressToInfo.hasOwnProperty(lowercase_address)) {
    return addressToInfo[lowercase_address];
  }
  return null;
};

export const getIsMerchantAddress = (address) => {
  const lowercase_address = address.toLowerCase();
  if (addressToInfo.hasOwnProperty(lowercase_address)) {
    return Boolean(Number(addressToInfo[lowercase_address].is_feeless_in));
  }
  return false;
};

