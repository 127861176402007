import React, { Component } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client'
import axios from 'axios';
import fetchUserTokenTransferEvents from 'utils/fetchUserTokenTransferEvents';
import { formatTransaction, formatTransactionIcon, formatAddress, formatTimestamp, formatWeiToEther } from 'utils/formatData';
import * as moment from 'moment';
import BigNumber from 'utils/BigNumber';
// import * as retry from 'async-retry';


function formatRefundInfo(event) {
  if (!!event.refundTransferEvent) {
    return <span>
      { `-${formatWeiToEther(event.refundTransferEvent.value)} ` }
      { formatTransactionIcon(event.refundTransferEvent.hash) }
    </span>;
  }
  return '';
}

function formatOrder(order) {
  return {
    "timeStamp": Math.floor(Date.parse(order.hash_time) / 1000),
    "hash": order.hash,
    "from": order.data.transfer_from ? order.data.transfer_from.toLowerCase() :
      (order.wallet ? order.wallet.toLowerCase() : ''),
    "contractAddress": "0x67ab11058ef23d0a19178f61a050d3c38f81ae21",
    "to": order.data.params._to.toLowerCase(),
    "value": order.data.params._value,
    "status": order.status,
    "index": parseInt(order._id),
    "indexIsOrderId": true,
  }
}

function formatTransferEvent(event, index) {
  return {
    "timeStamp": parseInt(event.timeStamp),
    "hash": event.hash,
    "from": event.from,
    "contractAddress": event.contractAddress,
    "to": event.to,
    "value": event.value,
    "status": 'success',
    "index": '',
    "indexIsOrderId": false,
  }
}

function formatOrderIndex(index, indexIsOrderId) {
  return <span>{ index }</span>
}

function formatStatus(statusStr) {
  if (statusStr === 'success') {
    return <i className="text-success fas fa-check-double" title="transaction confirmed"></i>
  }
  if (statusStr === 'pending') {
    return <i className="text-success fas fa-check" title="transaction submitted"></i>
    // return <i className="text-secondary fas fa-ellipsis-h"></i>
  }
  if (statusStr === 'error') {
    return <i className="text-danger fas fa-times" title="transaction failed"></i>
  }
  return "";
}

function formatOrderRowClass(statusStr) {
  if (statusStr === 'pending') {
    // return "order-pending"
    return ""
  }
  if (statusStr === 'error') {
    return "order-error"
  }
  return "";
}

const getMonthIdOfOrder = (order) => {
  // in current timezone
  return moment.unix(order.timeStamp).format("YYYY-MM");
}

const groupOrdersByMonth = (orders) => {
  // orders must be chronologically sorted
  // ordersByMonth = [ { monthId: '2020-01', orders: [] }, ... ]
  const ordersByMonth = [];
  for (const order of orders) {
    const monthId = getMonthIdOfOrder(order);
    if (ordersByMonth.length === 0 || ordersByMonth[ordersByMonth.length - 1].monthId !== monthId) {
      ordersByMonth.push({
        monthId: monthId,
        orders: [],
      });
    }
    ordersByMonth[ordersByMonth.length - 1].orders.push(order);
  }

  // add totalValue to monthlyData
  ordersByMonth.forEach(monthlyData => {
    let totalValue = new BigNumber(0);
    for (const order of monthlyData.orders) {
      if (order.status === "success") {
        totalValue = totalValue.plus(new BigNumber(order.value));
        if (!!order.refundTransferEvent) {
          totalValue = totalValue.minus(new BigNumber(order.refundTransferEvent.value));
        }
      }
    }
    monthlyData.totalValue = totalValue;
  })

  return ordersByMonth;
}

class MerchantOrders extends Component {
  state = {
    selfTokenContractAddress: '0x67ab11058ef23d0a19178f61a050d3c38f81ae21',
    selfPayServerUrl: 'https://api.selftoken.co',

    txHashToTransferEvent: {},
    completedIncomingTransfers: [],
    completedTxHashes: new Set(),
    incomingOrders: [],
    incomingOrderTxHashes: new Set(),
    displayedOrders: [],
    displayedOrdersByMonth: [],
    abnormalTxDocs: {
      testTxDocs: [],
      txHashToTestTxDoc: {},
      refundTxDocs: [],
      paymentTxHashToRefundTxDoc: {},
    },

    pollIntervalIds: [],
    isOrdersReceived: false,
    lastUpdatedTimeStamp: Math.floor(Date.now() / 1000),
    nowTimeStamp: Math.floor(Date.now() / 1000),
  };

  async componentDidMount() {
    const { selfPayServerUrl } = this.state;

    // await this.fetchAbnormalTxDocs();
    this.fetchAbnormalTxDocs().then();

    await this.fetchCompletedIncomingTransfers();

    // subscribe to self pay server (for delegated transfer)
    const socket = io(selfPayServerUrl);
    socket.on('orders', (orders) => {
      this.setState({ isOrdersReceived: true })
      this.processOrders(orders);
    });

    // trigger the server to broadcast orders
    this.getOrders(1000);

    // poll
    const pollIntervalIds = [];
    pollIntervalIds.push(
      setInterval(() => {
        this.setState({
          nowTimeStamp: Math.floor(Date.now() / 1000),
        });
      }, 1000)
    );
    pollIntervalIds.push(
      setInterval(() => {
        this.fetchCompletedIncomingTransfers();
      }, 15000)
    );
    pollIntervalIds.push(
      setInterval(() => {
        this.fetchAbnormalTxDocs();
      }, 60000)
    );
    this.setState({
      pollIntervalIds,
    });
  }

  componentWillUnmount = () => {
    const { pollIntervalIds } = this.state;
    for (const intervalId of pollIntervalIds) {
      clearInterval(intervalId);
    }
  }

  async fetchAbnormalTxDocs() {
    console.log('fetchAbnormalTxDocs');

    const { selfPayServerUrl } = this.state;

    const abnormal_txs = await axios.get(`${selfPayServerUrl}/abnormal_txs`);
    console.log('abnormal_txs', abnormal_txs);

    const testTxDocs = abnormal_txs.data.data.tx_test;
    const txHashToTestTxDoc = {};
    for (const testTxDoc of abnormal_txs.data.data.tx_test) {
      txHashToTestTxDoc[testTxDoc.tx_hash] = testTxDoc;
    }

    const refundTxDocs = abnormal_txs.data.data.tx_refund;
    const paymentTxHashToRefundTxDoc = {};
    for (const refundTxDoc of abnormal_txs.data.data.tx_refund) {
      paymentTxHashToRefundTxDoc[refundTxDoc.payment_tx_hash] = refundTxDoc;
    }

    this.setState({
      abnormalTxDocs: {
        testTxDocs,
        txHashToTestTxDoc,
        refundTxDocs,
        paymentTxHashToRefundTxDoc,
      },
    });
  }

  getOrders(retryAfterMs) {
    console.log('getOrders', retryAfterMs);
    console.log('this.state.isOrdersReceived', this.state.isOrdersReceived);

    const { selfPayServerUrl } = this.state;

    if (this.state.isOrdersReceived) { return; }

    axios.get(`${selfPayServerUrl}/getOrders`);

    // try again after x seconds
    setTimeout(() => { this.getOrders(retryAfterMs * 2) }, retryAfterMs);
  }

  async fetchCompletedIncomingTransfers() {
    console.log('fetchCompletedIncomingTransfers');

    const { merchantAddress } = this.props;
    const { selfTokenContractAddress } = this.state;

    const merchantSelfTokenTransferEvents = await fetchUserTokenTransferEvents(selfTokenContractAddress, merchantAddress); // ascending order

    if (!Array.isArray(merchantSelfTokenTransferEvents)) {
      console.warn("fetchCompletedIncomingTransfers failed.");
      return;
    }

    // console.log('merchantSelfTokenTransferEvents', merchantSelfTokenTransferEvents);
    this.setState({
      lastUpdatedTimeStamp: Math.floor(Date.now() / 1000),
    });

    // update this.state.txHashToTransferEvent
    merchantSelfTokenTransferEvents.forEach(event => {
      this.state.txHashToTransferEvent[event.hash] = event;
    });

    // filter incoming tx
    // new is relative to the previously fetched data
    const newCompletedIncomingTransfers = merchantSelfTokenTransferEvents.filter(event => event.to.toLowerCase() === merchantAddress.toLowerCase());

    // filter outgoing tx
    const newCompletedOutgoingTransfers = merchantSelfTokenTransferEvents.filter(event => event.from.toLowerCase() === merchantAddress.toLowerCase());

    // if no new transfers, do nothing
    if (newCompletedIncomingTransfers.length < this.state.completedIncomingTransfers.length) {
      return;
    }

    const completedTxHashes = new Set(merchantSelfTokenTransferEvents.map(event => event.hash));

    await this.setState({
      completedIncomingTransfers: newCompletedIncomingTransfers,
      completedOutgoingTransfers: newCompletedOutgoingTransfers,
      completedTxHashes,
    });
    this.mergeOrders();
  }

  async processOrders(orders) {
    console.log('processOrders');

    const { merchantAddress } = this.props;

    // filter incoming orders to this merchant
    const incomingOrders = orders.filter(order => {
      return order.tx && (order.data.params._to.toLowerCase() === merchantAddress.toLowerCase())
    });

    const incomingOrderTxHashes = new Set(incomingOrders.map(order => order.hash))

    await this.setState({
      incomingOrders,
      incomingOrderTxHashes,
      lastUpdatedTimeStamp: Math.floor(Date.now() / 1000),
    });
    this.mergeOrders();
  }

  mergeOrders() {
    console.log('mergeOrders');

    const { fromTimeStamp, isGroupedByMonth } = this.props;
    const {
      txHashToTransferEvent,
      completedIncomingTransfers,
      completedTxHashes,
      incomingOrders,
      incomingOrderTxHashes,
      abnormalTxDocs,
    } = this.state;

    /* process orders */

    // update status
    incomingOrders.forEach(order => {
      if (completedTxHashes.has(order.hash)) {
        order.status = 'success';
      }
    });

    // // filter out completed orders
    // const uncompletedIncomingOrders = incomingOrders.filter(order => {
    //   return !(completedTxHashes.has(order.hash));
    // });
    // // format orders
    // const formattedUncompletedIncomingOrders = uncompletedIncomingOrders.map(formatOrder);

    // format orders
    const formattedIncomingOrders = incomingOrders.map(formatOrder);

    /* process transfer events */

    // filter out delegated transfers (in orders)

    const completedIncomingTransfersNotOrders = completedIncomingTransfers.filter(event => {
      return !(incomingOrderTxHashes.has(event.hash));
    });

    // format transfer events
    const formattedCompletedIncomingTransfersNotOrders = completedIncomingTransfersNotOrders.map(formatTransferEvent);

    // merge completedIncomingTransfers and uncompletedIncomingOrders
    let displayedOrders = formattedIncomingOrders.concat(formattedCompletedIncomingTransfersNotOrders);

    // hide testing txs
    displayedOrders = displayedOrders.filter(order => {
      return !abnormalTxDocs.txHashToTestTxDoc.hasOwnProperty(order.hash)
    });
    // hide txs before fromTimeStamp if necessary
    if (!!fromTimeStamp) {
      displayedOrders = displayedOrders.filter(order => order.timeStamp >= fromTimeStamp);
    }

    // link refund txs to payment txs
    displayedOrders.forEach(order => {
      if (abnormalTxDocs.paymentTxHashToRefundTxDoc.hasOwnProperty(order.hash)) {
        const refundTxDoc = abnormalTxDocs.paymentTxHashToRefundTxDoc[order.hash];
        const refundTxHash = refundTxDoc.refund_tx_hash;
        const refundTransferEvent = txHashToTransferEvent[refundTxHash];
        order.refundTransferEvent = refundTransferEvent;
        order.refundTxDoc = refundTxDoc;
      }
    })

    // sort displayedOrders in descending chronological order
    displayedOrders.sort((o1, o2) => {
      let order = o1.timeStamp - o2.timeStamp;
      if (order === 0) { order = o1.index - o2.index; }
      return -order;
    });

    this.setState({
      displayedOrders,
    });

    if (isGroupedByMonth) {
      this.setState({
        displayedOrdersByMonth: groupOrdersByMonth(displayedOrders),
      });
    }
  }

  render() {
    const {
      isGroupedByMonth,
    } = this.props;

    const {
      displayedOrders,
      displayedOrdersByMonth,
      lastUpdatedTimeStamp,
      nowTimeStamp,
    } = this.state;

    const updatedSecondsAgo = Math.max(0, nowTimeStamp - lastUpdatedTimeStamp);

    if (isGroupedByMonth) {
      return (
        <div className="position-relative mb-5">
          <div className="text-additional text-right">{ updatedSecondsAgo } 秒前更新</div>
          {
            displayedOrdersByMonth.map(monthlyData => {
              return (
                <div className="order-group-by-month mb-5">
                  <div className="month-title h5 text-center">{ monthlyData.monthId }</div>
                  <div className="month-title text-center mb-3">Total: { formatWeiToEther(monthlyData.totalValue, 2) } SELF</div>
                  <table className="orders-table">
                    <thead className="text-primary">
                      <tr>
                        <th>ID</th>
                        <th>From</th>
                        <th>SELF</th>
                        <th>Confirmed At</th>
                        <th>TX</th>
                        <th>Status</th>
                        <th>Refund</th>
                      </tr>
                    </thead>
                    <tbody className="text-secondary">
                      {
                        monthlyData.orders.map((event, index) => {
                          return (
                            <tr key={ event.hash } className={ formatOrderRowClass(event.status) }>
                              <td>{ formatOrderIndex(event.index, event.indexIsOrderId) }</td>
                              <td>{ formatAddress(event.from) }</td>
                              <td>{ formatWeiToEther(event.value, 2) }</td>
                              <td>{ formatTimestamp(event.timeStamp) }</td>
                              <td>{ formatTransaction(event.hash, true) }</td>
                              <td>{ formatStatus(event.status) }</td>
                              <td>{ formatRefundInfo(event) }</td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </div>
              );
            })
          }
        </div>
      );
    }

    return (
      <div className="position-relative" style={{ maxHeight: "calc(100vh - 10rem)", overflow: "scroll", paddingBottom: "1rem" }}>
        <div className="text-additional text-right">{ updatedSecondsAgo } 秒前更新</div>
        {
          <table className="orders-table">
            <thead className="text-primary">
              <tr>
                <th>ID</th>
                <th>From</th>
                <th>SELF</th>
                <th>Confirmed At</th>
                <th>TX</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="text-secondary">
              {
                displayedOrders.map((event, index) => {
                  return <tr key={ event.hash } className={ formatOrderRowClass(event.status) }>
                    <td>{ formatOrderIndex(event.index, event.indexIsOrderId) }</td>
                    <td>{ formatAddress(event.from) }</td>
                    <td>{ formatWeiToEther(event.value, 2) }</td>
                    <td>{ formatTimestamp(event.timeStamp) }</td>
                    <td>{ formatTransactionIcon(event.hash) }</td>
                    <td>{ formatStatus(event.status) }</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        }
      </div>
    );
  }
}

MerchantOrders.propTypes = {
  strings: PropTypes.object,
  accounts: PropTypes.array,
  fromTimeStamp: PropTypes.number,
  isGroupedByMonth: PropTypes.bool,
};

MerchantOrders.defaultProps = {
  strings: {},
  accounts: [],
  fromTimeStamp: 0,
  isGroupedByMonth: true,
};

export default MerchantOrders;
