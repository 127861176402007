import React, { Component } from "react";
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

class QrcodeImg extends Component {
  state = {
    qrcodeImgData: "",
  }

  componentDidMount() {
    this.updateQrcodeImg(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateQrcodeImg(nextProps);
  }

  updateQrcodeImg = async (nextProps) => {
    const qrcodeImgData = await QRCode.toDataURL(nextProps.text, {
      width: nextProps.width,
      margin: nextProps.margin,
    });
    this.setState({
      qrcodeImgData,
    });
  };

  render() {
    const { qrcodeImgData } = this.state;

    return <img src={ qrcodeImgData } alt="qrcode" />
  }
}

QrcodeImg.propTypes = {
  text: PropTypes.string,
  width: PropTypes.number,
  margin: PropTypes.number,
};

QrcodeImg.defaultProps = {
  text: "",
  width: 256,
  margin: 2,
};

export default QrcodeImg;
