const iconFolderUrl = "https://raw.githubusercontent.com/self-token/global-data/master/wallet_app_icons/"

export default [
  {
    id: "messenger",
    isContractWallet: false,
    isDappBrowser: true,
    name: "SELF PAY",
    iconUrl: iconFolderUrl + "messenger_256.png",
    paymentGuideMessages: [
      "1. 使用相機或 QR code 掃描器掃碼並在 Messenger 開啟",
      "2. 送出付款金額 $tokenAmount 即可完成支付",
    ],
  }, {
    id: "trust_wallet", // lowercase, may be used in URL query
    isContractWallet: false,
    isDappBrowser: true,
    name: "Trust Wallet",
    iconUrl: iconFolderUrl + "trust_wallet_256.png",
    paymentGuideMessages: [
      "1. 使用相機或 QR code 掃描器掃碼", // 開啟網頁
      "2. 在 Trust Wallet 中開啟支付頁面", // 按下「Open in Trust Wallet」
      "3. 確認店家與數額無誤後，按下 Send 並簽名即可完成支付",
    ],
  }, {
    id: "dapp_pocket",
    isContractWallet: false,
    isDappBrowser: true,
    name: "Dapp Pocket",
    iconUrl: iconFolderUrl + "dapp_pocket_256.png",
    paymentGuideMessages: [
      "1. 打開 Dapp Pocket 進入「錢包」分頁", // 點選下方「瀏覽」分頁
      "2. 點擊左上角掃碼圖示掃碼前往支付頁面並授權帳戶",
      "3. 確認店家與數額無誤後，按下 Send 並簽章即可完成支付",
    ],
  }, {
  //   id: "eventoken",
  //   isContractWallet: false,
  //   isDappBrowser: false,
  //   name: "EvenToken",
  //   iconUrl: iconFolderUrl + "eventoken_256.png",
  //   paymentGuideMessages: [
  //     "請傳送 $tokenAmount SELF 到此錢包地址",
  //     "等候 1~3 分鐘，待交易上鏈即完成支付",
  //     "（需要負擔 ETH 手續費）",
  //   ],
  // }, {
    id: "imtoken",
    isContractWallet: false,
    isDappBrowser: true,
    name: "imToken",
    iconUrl: iconFolderUrl + "imtoken_256.png",
    paymentGuideMessages: [
      "1. 打開 imToken 進入「錢包」分頁", // 點選下方「瀏覽」分頁
      "2. 點擊右上角掃碼圖示掃碼進入支付頁面",
      "3. 確認店家與數額無誤後，按下 Send 並簽名即可完成支付",
    ],
  }, {
    id: "coinbase_wallet",
    isContractWallet: false,
    isDappBrowser: true,
    name: "Coinbase Wallet",
    iconUrl: iconFolderUrl + "coinbase_wallet_256.png",
    paymentGuideMessages: [
      "1. 使用相機或 QR code 掃描器掃碼", // 開啟網頁
      "2. 複製網址後在 Coinbase Wallet 的瀏覽器中貼上開啟支付頁面",
      "3. 確認店家與數額無誤後，按下 Send 並簽名即可完成支付",
    ],
  }, {
    id: "blocto",
    isContractWallet: true,
    isDappBrowser: true,
    name: "Blocto",
    iconUrl: iconFolderUrl + "blocto_256.png",
    paymentGuideMessages: [
      "1. 打開 Blocto 點選右上角人像圖示、點選支付",
      "2. 找到 ETHEREUM 卡片，在 TOKEN 列表中找到 SELF TOKEN",
      "3. 點選左下角「轉帳」，掃碼填入收款地址，輸入付款金額，送出交易",
      "等候 1~3 分鐘，待交易上鏈即完成支付",
    ],
  }, {
    id: "metamask_mobile_app",
    isContractWallet: false,
    isDappBrowser: true,
    name: "MetaMask App",
    iconUrl: iconFolderUrl + "metamask_256.png",
    paymentGuideMessages: [
      "1. 打開 MetaMask App，點選左上角 ≡ 打開選單，點選「Wallet」", // 開啟網頁
      "2. 點選右上角掃碼圖示掃碼開啟支付頁面",
      "3. 確認店家與數額無誤後，按下 Send 並簽名即可完成支付",
    ],
  }, {
    id: "opera_touch",
    isContractWallet: false,
    isDappBrowser: true,
    name: "Opera Touch",
    iconUrl: iconFolderUrl + "opera_touch_256.png",
    paymentGuideMessages: [
      "1. 打開 Opera Touch 按下搜尋欄", // 點選下方「瀏覽」分頁
      "2. 點擊鍵盤左上角掃碼圖示掃碼進入支付頁面",
      "3. 確認店家與數額無誤後，按下 Send 並簽名即可完成支付",
    ],
  }, {
    id: "cipher_wallet",
    isContractWallet: false,
    isDappBrowser: true,
    name: "Cipher Wallet",
    iconUrl: iconFolderUrl + "cipher_wallet_256.png",
    paymentGuideMessages: [
      "1. 使用相機或 QR code 掃描器掃碼", // 開啟網頁
      "2. 複製網址後在 Cipher Wallet 的瀏覽器中貼上開啟支付頁面",
      "3. 確認店家與數額無誤後，按下 Send 並簽名即可完成支付",
    ],
  }, {
  //   id: "argent",
  //   isContractWallet: true,
  //   isDappBrowser: true,
  //   name: "Argent",
  //   iconUrl: iconFolderUrl + "argent_256.png",
  // }, {
    id: "any_dapp_browser",
    isContractWallet: false,
    isDappBrowser: true,
    name: "任何 DApp 瀏覽器",
    iconUrl: iconFolderUrl + "any_dapp_browser_white.png",
    paymentGuideMessages: [
      "1. 使用相機或 QR code 掃描器掃碼", // 開啟網頁
      "2. 複製網址後在您使用的 DApp 瀏覽器中貼上開啟支付頁面",
      "3. 確認店家與數額無誤後按下 Send 並簽名即可完成支付",
    ],
  }, {
    id: "any_erc20_token_wallet",
    isContractWallet: false,
    isDappBrowser: false,
    name: "任何 ERC20 錢包",
    iconUrl: iconFolderUrl + "any_erc20_token_wallet_white.png",
    paymentGuideMessages: [
      "請傳送 $tokenAmount SELF 到此錢包地址",
      "等候 1~3 分鐘，待交易上鏈即完成支付",
      "（需要負擔 ETH 手續費）",
    ],
  },
];
