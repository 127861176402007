// bn.js is what web3.utils.BN actually is
// but bn.js only handles integers, so we use bignumber.js for more behaviors
import { BigNumber } from 'bignumber.js'; // not web3.utils.BN in 1.0.0-beta.34


const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: ''
}
BigNumber.config({ FORMAT: fmt });
BigNumber.config({ EXPONENTIAL_AT: 1e+9 });

export default BigNumber;
