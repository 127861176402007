import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './custom.scss';
import MerchantApp from 'components/merchant/MerchantApp';
import NoMatchPage from 'components/NoMatchPage';
// import App from './App';
import * as serviceWorker from './serviceWorker';


// overwrite console.log
if (
  window.location.hostname === 'test.selftoken.co' ||
  window.location.hostname === 'testdapp.selftoken.co' ||
  window.location.hostname.startsWith('192.168')
) {
  var logger = document.createElement('div');
  logger.style.cssText = "color: white;";
  document.body.appendChild(logger);

  // show logs in html
  console.log = (...messages) => {
    for (const message of messages) {
      if (typeof message == 'object') {
        try {
          logger.innerHTML += (JSON && JSON.stringify ? JSON.stringify(message) : message) + '<br />';
        } catch (error) {
          console.log("error when console.log object");
          console.error(error);
        }
      } else {
          logger.innerHTML += message.toString() + '<br />';
      }
    }
  }
} else if (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'
) {

} else {
  // console.log will not work in production
  console.log = () => {};
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/merchant/:address" component={MerchantApp}/>
      {/* <Route exact path="/app" component={App}/> */}
      <Route component={NoMatchPage}/>
      {/* <Route component={App}/> */}
    </Switch>
  </Router>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
