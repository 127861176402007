import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import NumericInput from 'react-numeric-input';
// import NumericInput from 'components/react-numeric-input/index';
// import Modal from 'react-modal';
import { Modal, Form } from 'react-bootstrap';
import qs from 'qs';
import Cookies from 'universal-cookie';

import QrcodeImg from 'components/QrcodeImg.js';
// import MerchantReceiveTokenProcedure from 'components/merchant/MerchantReceiveTokenProcedure.js';
import walletApps from 'data/walletApps.js';



function isNumeric(num){
  return !isNaN(num);
}

const currencyList = ['ntd', 'self', 'none'];
const currencySet = new Set(currencyList);
const cookies = new Cookies();

const PAYMENT_STEPS = Object.freeze({
  select_wallet_app: 1,
  scan_qrcode: 2,
  check_tx: 3,
});
const FIRST_PAYMENT_STEP = PAYMENT_STEPS.select_wallet_app;
// const LAST_PAYMENT_STEP = PAYMENT_STEPS.check_tx;

const computeReceivePaymentUrl = (baseUrl, address, selfAmount, browserId) => {
  return (
    baseUrl + 'trans'
    + `?to=${address}`
    + ( selfAmount ? `&self=${selfAmount}` : "" )
    + `&browser=${browserId}`
  );
}

class MerchantReceiveToken extends Component {
  state = {
    baseUrl: "https://selfer.selftoken.co/",
    isSelfAmountDesignated: true,
    isShowingPaymentProcedureModal: false,
    isShowingPayWithDappBrowserModal: false,
    isShowingPayWithAnyWalletModal: false,
    isTesting: false,
    selfAmount: 100,
    selfAmountInputValue: "100",
    isValidSelfAmountInputValue: true,
    ntdAmount: 300,
    ntdAmountInputValue: 300,
    isValidNtdAmountInputValue: true,
    selectedCurrency: 'ntd', // 'ntd', 'self', 'none'
    isShowingMoreInfoForPayingWithDappBrowser: false,

    selectedWalletApp: null,
    paymentStep: FIRST_PAYMENT_STEP,
    isShowingAllWalletApps: false,
  }

  componentDidMount = async () => {
    await this.loadSelectedCurrencyFromCookies();

    // change baseUrl to window.location.origin if is testing
    try {
      console.log('MerchantReceiveToken this.props.location', this.props.location);
      const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (!!queries.testing) {
        const baseUrl = window.location.origin + '/#/';
        this.setState({
          isTesting: true,
          baseUrl,
        });
        console.log('is testing. Change baseUrl to ' + baseUrl);
      }
    } catch (error) {
      console.error('MerchantReceiveToken componentDidMount ', error);
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  // handleAmountChange = (value) => {
  //   this.setState({
  //     selfAmount: value,
  //   });
  // }

  // handleNumericInputFocus = (event) => {
  //   if (event.target.select) {
  //     event.target.select();
  //   }
  // }

  loadSelectedCurrencyFromCookies = async () => {
    const selectedCurrency = cookies.get('selectedCurrency');
    if (currencySet.has(selectedCurrency)) {
      this.setState({
        selectedCurrency,
        isSelfAmountDesignated: (selectedCurrency !== 'none'),
      });
    }
  }

  saveSelectedCurrencyToCookies = async (selectedCurrency) => {
    cookies.set('selectedCurrency', selectedCurrency, { path: '/' });
  }

  handleSelfAmountSubmit = (event) => {
    event.preventDefault();
    if (this.state.isValidSelfAmountInputValue) {
      this.showPayWithDappBrowserModal();
    }
  }

  handleSelfAmountInputChange = (event) => {
    console.log('handleSelfAmountInputChange', event);

    // will be empty string when number is not valid
    const selfAmountInputValue = event.target.value;

    // if is empty string
    if (!selfAmountInputValue) {
      this.setState({
        // selfAmountInputValue: '',
        selfAmount: 0,
        isValidSelfAmountInputValue: false,
        // ntdAmountInputValue: '',
        ntdAmount: 0,
        // isValidNtdAmountInputValue: false,
      });
    }

    const selfAmount = Number(selfAmountInputValue);
    const isValidSelfAmountInputValue = isNumeric(selfAmount);
    if (!isValidSelfAmountInputValue) {
      this.setState({
        // selfAmountInputValue: '',
        selfAmount: 0,
        isValidSelfAmountInputValue: false,
        // ntdAmountInputValue: '',
        ntdAmount: 0,
        // isValidNtdAmountInputValue: false,
      });
    }

    const ntdAmount = selfAmount * 3;
    const ntdAmountInputValue = ntdAmount.toString();
    this.setState({
      selfAmountInputValue,
      selfAmount,
      isValidSelfAmountInputValue: true,
      ntdAmountInputValue,
      ntdAmount,
      isValidNtdAmountInputValue: true,
    });
  }

  handleNtdAmountInputChange = (event) => {
    console.log('handleNtdAmountInputChange', event);

    // will be empty string when number is not valid
    const ntdAmountInputValue = event.target.value;

    // if is empty string
    if (!ntdAmountInputValue) {
      this.setState({
        // selfAmountInputValue: '',
        selfAmount: 0,
        // isValidSelfAmountInputValue: false,
        // ntdAmountInputValue: '',
        ntdAmount: 0,
        isValidNtdAmountInputValue: false,
      });
    }

    const ntdAmount = Number(ntdAmountInputValue);
    const isValidNtdAmountInputValue = isNumeric(ntdAmount);
    if (!isValidNtdAmountInputValue) {
      this.setState({
        // selfAmountInputValue: '',
        selfAmount: 0,
        // isValidSelfAmountInputValue: false,
        // ntdAmountInputValue: '',
        ntdAmount: 0,
        isValidNtdAmountInputValue: false,
      });
    }

    const selfAmount = Math.round(ntdAmount / 3);
    const selfAmountInputValue = selfAmount.toString();
    this.setState({
      selfAmountInputValue,
      selfAmount,
      isValidSelfAmountInputValue: true,
      ntdAmountInputValue,
      ntdAmount,
      isValidNtdAmountInputValue: true
    });
  }

  selectEventTarget = (event) => {
    if (event.target.select) {
      event.target.select();
    }
  }

  showPaymentProcedureModal = () => {
    this.setState({
      isShowingPaymentProcedureModal: true,
      isShowingPayWithDappBrowserModal: false,
      isShowingPayWithAnyWalletModal: false,
    });
  }

  showPayWithDappBrowserModal = () => {
    this.setState({
      isShowingPayWithDappBrowserModal: true,
      isShowingPayWithAnyWalletModal: false,
    });
  }

  showPayWithAnyWalletModal = () => {
    this.setState({
      isShowingPayWithDappBrowserModal: false,
      isShowingPayWithAnyWalletModal: true,
    });
  }

  closeModals = () => {
    this.setState({
      isShowingPaymentProcedureModal: false,
      isShowingPayWithDappBrowserModal: false,
      isShowingPayWithAnyWalletModal: false,
    });
    // reset paymentStep after modal disappears
    setTimeout(() => {
      this.setState({
        paymentStep: FIRST_PAYMENT_STEP,
        isShowingAllWalletApps: false,
      });
    }, 300);
  }

  handleSelectedCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    this.setState({
      selectedCurrency,
      isSelfAmountDesignated: (selectedCurrency !== 'none'),
    });
    this.saveSelectedCurrencyToCookies(selectedCurrency).then();
  }

  toggleShowingMoreInfoForPayingWithDappBrowser = () => {
    this.setState({
      isShowingMoreInfoForPayingWithDappBrowser: !this.state.isShowingMoreInfoForPayingWithDappBrowser
    });
  }

  selectWalletApp = (app) => {
    this.setState({
      selectedWalletApp: app,
      paymentStep: PAYMENT_STEPS.scan_qrcode,
    });
  }

  toPreviousPaymentStep = () => {
    const { paymentStep } = this.state;
    if (paymentStep > FIRST_PAYMENT_STEP) {
      this.setState({
        paymentStep: paymentStep - 1,
      });
    } else {
      this.setState({
        paymentStep: FIRST_PAYMENT_STEP,
      });
    }
  }

  showAllWalletApps = () => {
    this.setState({
      isShowingAllWalletApps: true,
    });
  }

  render() {
    const {
      strings,
      address,
      addressInfo,
    } = this.props;

    if (!address) {
      return <div>
        <div className="alert alert-danger">{ strings.msg_no_account }</div>
      </div>;
    }

    const {
      baseUrl,
      isSelfAmountDesignated,
      selfAmount,
      selfAmountInputValue,
      isValidSelfAmountInputValue,
      // ntdAmount,
      ntdAmountInputValue,
      isValidNtdAmountInputValue,
      selectedCurrency,
      // isShowingMoreInfoForPayingWithDappBrowser,
      selectedWalletApp,
      paymentStep,
      isShowingAllWalletApps,
    } = this.state;

    const closeModalButton = (
      <button type="button" className="custom-modal-close-button" onClick={this.closeModals}>
        <span aria-hidden="true"><i className="fas fa-times"></i></span>
        <span className="sr-only">Close</span>
      </button>
    );

    const toPreviousPaymentStepButton = (paymentStep === FIRST_PAYMENT_STEP) ? null :(
      <button type="button" className="custom-modal-back-button" onClick={this.toPreviousPaymentStep}>
        <span aria-hidden="true"><i className="fas fa-chevron-left"></i></span>
        <span className="sr-only">Back</span>
      </button>
    );

    // let modalTitle = "請選擇支付者使用的錢包 App";
    const walletAppSelector = (
    <div className="payment-step-select-wallet-app text-center">
      { closeModalButton }
      <div className="h4 px-4">選擇支付用的錢包 App</div>
      <div className="payment-wallet-app-buttons-container">
        {
          isShowingAllWalletApps ?
          walletApps.map((app) => { return (
            <div key={ "wallet-app-option-" + app.id } className="wallet-app-block" onClick={ () => { this.selectWalletApp(app) } }>
              <div>
                <img className="wallet-app-icon mb-1" src={ app.iconUrl } alt={ app.name + " icon" } />
              </div>
              <div>{ app.name }</div>
            </div>
          );})
          :
          walletApps.slice(0, 3).map((app) => { return (
            <div key={ "wallet-app-option-" + app.id } className="wallet-app-block" onClick={ () => { this.selectWalletApp(app) } }>
              <div>
                <img className="wallet-app-icon mb-1" src={ app.iconUrl } alt={ app.name + " icon" } />
              </div>
              <div>{ app.name }</div>
            </div>
          );})
          .concat([
            <div key="wallet-app-option-more-apps" className="wallet-app-block" onClick={ this.showAllWalletApps }>
              <div>
                <img className="wallet-app-icon mb-1" alt={ "show all wallet apps" } src="https://raw.githubusercontent.com/self-token/global-data/master/wallet_app_icons/more_apps.png" />
              </div>
              <div>More Apps</div>
            </div>
          ])
        }
      </div>
    </div>);

    let walletAppPaymentGuide = null;
    if (!!selectedWalletApp) {
      const displayedSelfAmount = isSelfAmountDesignated ? selfAmount : '';
      let qrcodeContent = address;
      let qrcodeMsg = address;
      if (selectedWalletApp.id === 'messenger') {
        qrcodeContent = `https://m.me/selftoken?ref=merchant--${address}`;
        qrcodeMsg = `在 Messenger 向 ${address} 支付 SELF`;
      }
      else if (selectedWalletApp.isDappBrowser && !selectedWalletApp.isContractWallet) {
        qrcodeContent = computeReceivePaymentUrl(
          baseUrl,
          address,
          isSelfAmountDesignated ? selfAmount : null,
          selectedWalletApp.id
        );
        qrcodeMsg = `在 SELF DApp 向 ${address} 支付 ${displayedSelfAmount} SELF`;
      }
      else {
        qrcodeContent = address;
        qrcodeMsg = address;
      }

      walletAppPaymentGuide = (
        <div className="payment-step-scan-qrcode text-center">
          { toPreviousPaymentStepButton }
          { closeModalButton }
          <div className="h5 mb-3">
            <div className="">使用 { selectedWalletApp.name }</div>
            <div className="">
              向 <span className="text-white">{ addressInfo.full_name }</span> 支付 <span className="text-white">{ displayedSelfAmount }</span> SELF
            </div>
          </div>

          <div className="mb-2">
            {
              selectedWalletApp.paymentGuideMessages.map(msg => {
                const splitMsg = msg.split('$tokenAmount')
                if (splitMsg.length === 2) {
                  return <div>{ splitMsg[0] }<span className="text-white">{ displayedSelfAmount }</span>{ splitMsg[1] }</div>
                }
                return <div>{ msg }</div>
              })
            }
          </div>

          <div className="mb-1">
            <div className="mb-1">
              <QrcodeImg text={ qrcodeContent } width={ 320 } margin={ 2 } />
            </div>
            <div className="text-additional">{ qrcodeMsg }</div>
          </div>
        </div>
      );
    }

    let modalContent = walletAppSelector;
    if (paymentStep === PAYMENT_STEPS.select_wallet_app) {
      modalContent = walletAppSelector;
    } else if (paymentStep === PAYMENT_STEPS.scan_qrcode) {
      modalContent = walletAppPaymentGuide;
    }

    return (
      <>
        <Modal
          size="lg"
          show={this.state.isShowingPaymentProcedureModal}
          onHide={this.closeModals}
          centered={ true }
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          {/* <button type="button" className="custom-modal-close-button close" onClick={this.closeModals}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button> */}
          <Modal.Body className="p-3">
            <div>
              {/* <button></button> */}
              {/* <div></div> */}
              {/* <button type="button" className="custom-modal-close-button" onClick={this.closeModals}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button> */}
            </div>

            { modalContent }
          </Modal.Body>
        </Modal>

        <div className="self-amount-inputter text-center">
          <div className="mb-1">
            <Form.Control as="select"
              className="merchant-currency-selector"
              value={selectedCurrency}
              onChange={this.handleSelectedCurrencyChange}
            >
              <option value="ntd">{ strings.NTD }</option>
              <option value="self">{ strings.EN_SELF }</option>
              <option value="none">{ strings.unlimited_payment_amount }</option>
            </Form.Control>
          </div>

          <div className="mb-3">
            <form onSubmit={this.handleSelfAmountSubmit} noValidate>
              { selectedCurrency === "self" && <>
                <input
                  type="number"
                  className={
                    "numeric-input "
                    + (isValidSelfAmountInputValue ? "" : "not-valid-input ")
                  }
                  value={ selfAmountInputValue }
                  // onChange={this.handleSelfAmountInputChange}
                  onInput={this.handleSelfAmountInputChange}
                  onFocus={this.selectEventTarget}
                />
              </>}
              { selectedCurrency === "ntd" && <>
                <input
                  type="number"
                  className={
                    "numeric-input mb-1 "
                    + (isValidNtdAmountInputValue ? "" : "not-valid-input ")
                  }
                  value={ ntdAmountInputValue }
                  // onChange={this.handleSelfAmountInputChange}
                  onInput={this.handleNtdAmountInputChange}
                  onFocus={this.selectEventTarget}
                />
                <p>= {selfAmount} SELF</p>
              </>}
            </form>
          </div>

          <div className="mb-3">
            <button className="solid-rectangle-button bg-golden" onClick={ this.showPaymentProcedureModal }>{ strings.Receive_Payment }</button>
          </div>
        </div>
      </>
    );
  }
}

MerchantReceiveToken.propTypes = {
  address: PropTypes.string,
  strings: PropTypes.object,
  location: PropTypes.object,
  addressInfo: PropTypes.object,
};

MerchantReceiveToken.defaultProps = {
  address: '',
  strings: {},
  location: {},
  addressInfo: {},
};

export default MerchantReceiveToken;
