import { Component } from "react";

class NoMatchPage extends Component {
  componentWillMount() {
    window.location.replace("https://selfer.selftoken.co/");
  }

  render() {
    return null;
    // return <div className="text-center">
    //   <h3 className="mt-5 mb-3">Page Not Found</h3>
    //   <div className="mb-1">
    //     Sorry, we can't find the page you're looking for.<br />
    //     Please go back to the <a className="a" href="https://selftoken.co/">homepage</a> or try our new <a className="a" href="https://selfer.selftoken.co/">DApp</a>.
    //   </div>
    // </div>;
  }
}

export default NoMatchPage;
