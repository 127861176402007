import React, { Component } from "react";
import PropTypes from 'prop-types';

import styles from "styles/DoubleBorderTitle.module.css";

class DoubleBorderTitle extends Component {
  render() {
    const { className, paddingType, children } = this.props;

    const paddingClass = (paddingType === 'narrow') ? styles.narrowPadding : styles.normalPadding;

    return (
      <div className={ className }>
        <span className={["font-weight-bold", styles.doubleBorder, styles.doubleBorderTitle, paddingClass].join(' ')}>
          <span>{ children }</span>
          <span className={ styles.doubleBorderLineSegment1 }></span>
          <span className={ styles.doubleBorderLineSegment2 }></span>
          <span className={ styles.doubleBorderLineSegment3 }></span>
          <span className={ styles.doubleBorderLineSegment4 }></span>
        </span>
      </div>
    );
  }
}

DoubleBorderTitle.propTypes = {
  className: PropTypes.string,
  paddingType: PropTypes.string,
  // title: PropTypes.string,
};

DoubleBorderTitle.defaultProps = {
  className: "mb-3",
  paddingType: "normal",
  // title: "",
};

export default DoubleBorderTitle;
