import React, { Component } from "react";

import styles from "styles/Spinner.module.css";

class Spinner extends Component {
  render() {
    return <div className={ styles.spinner }></div>;
  }
}

// cover the whole screen with a colored div, and render spinner in it
class SpinnerCover extends Component {
  render() {
    return <div className={ styles["spinner-cover-container"] }>
      <div className={ styles["spinner-cover-background"] }>
        <div className={ styles.spinner }></div>
      </div>
    </div>;
  }
}

export {
  Spinner,
  SpinnerCover,
};
