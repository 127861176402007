import React, { Component } from "react";
import PropTypes from 'prop-types';

import * as addressUtils from "utils/addressUtils"; // do `await addressUtils.init()` before using


class AddressName extends Component {
  componentDidMount = async () => {
    await addressUtils.init();
  }
  render() {
    const { address } = this.props;
    const addressInfo = addressUtils.getAddressInfo(address);
    if (addressInfo) {
      const img = ((!!addressInfo.logo_url) ?
        <img className="address-name-logo" src={ addressInfo.logo_url } alt="address logo" /> :
        null
      );
      // const logo = require(imgPath);
      return <div className="h5 font-weight-light text-white mb-0">
        { img }
        { (!!addressInfo.name_beside_logo) &&
          <p className="mt-2">{ addressInfo.name_beside_logo }</p>
        }
      </div>
    }
    return null;
  }
}

AddressName.propTypes = {
  address: PropTypes.string,
};

AddressName.defaultProps = {
  address: '0x0000000000000000000000000000000000000000',
};

export default AddressName;
