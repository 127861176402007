import React, { Component } from "react";
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import localesData from 'data/locales.json';

import styles from "styles/LanguageSelector.module.css";

class LanguageSelector extends Component {
  state = {
    selectedLangCode: "zh-TW",
    cookies: new Cookies(),
  }

  componentDidMount = async () => {
    this.initLang();
  }

  isValidLangCode = langCode => {
    return localesData.hasOwnProperty(langCode);
  }

  initLang = async () => {
    const cookies = new Cookies();

    // Load selectedLangCode from cookies
    const selectedLangCodeInCookies = cookies.get('selectedLangCode'); // undefined if blank
    let selectedLangCode = "zh-TW";
    if (this.isValidLangCode(selectedLangCodeInCookies)) {
      selectedLangCode = selectedLangCodeInCookies;
      console.log("load language from cookies", selectedLangCode);
    } else {
      // Get browser language.
      const browserLangCode = (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
      console.log("browser language", browserLangCode);

      if (browserLangCode.substring(0, 2) === "en") {
        selectedLangCode = "en";
      } else if (browserLangCode === "zh" || browserLangCode === "zh-TW" || browserLangCode === "zh-HK") {
        selectedLangCode = "zh-TW";
      } else if (browserLangCode.substring(0, 2) === "zh") {
        selectedLangCode = "zh-CN";
      } else {
        selectedLangCode = "zh-TW";
      }
      this.setState({
        selectedLangCode: selectedLangCode,
      })
      console.log("load language from browser", selectedLangCode);
    }

    this.setLangCode(selectedLangCode);
    return selectedLangCode;
  }

  handleLangChange = (event) => {
    this.setLangCode(event.target.value);
  }

  setLangCode = (langCode) => {
    this.state.cookies.set('selectedLangCode', langCode, { path: '/' });
    this.setState({ selectedLangCode: langCode });
    this.props.setLangCode(langCode);
  }

  render() {
    return (
      <div>
        <span><i className="fas fa-globe-americas"></i></span>
        <select className={ styles["lang-select"] } value={this.state.selectedLangCode} onChange={this.handleLangChange}>
          <option value="zh-TW">繁體中文</option>
          <option value="zh-CN">简体中文</option>
          <option value="en">ENGLISH</option>
        </select>
      </div>
    );
  }
}

LanguageSelector.propTypes = {
  setLangCode: PropTypes.func,
};

LanguageSelector.defaultProps = {
  setLangCode: () => {},
};

export default LanguageSelector;
